import React, { FC } from 'react';
import {
  CreateProps,
  Create,
  TextInput,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

const OutletTableCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="order" />
      <ReferenceInput label="Outlet" source="outlet" reference="hw_outlet_clone">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default OutletTableCreate;
