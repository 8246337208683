import React, { FC } from 'react';
import {
  List, Datagrid, TextField, ListProps, NumberField,
} from 'react-admin';

const OutletList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={50}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <NumberField source="idoutlet" label="ID" />
        <TextField source="name" label="Name" />
      </Datagrid>
    </List>
  );
};

export default OutletList;
