import React, { FC } from 'react';
import {
  Show, SimpleShowLayout, TextField, ShowProps, Labeled, NumberField,
} from 'react-admin';
import OutletField from './components/OutletField';

const OutletTableShow: FC<ShowProps> = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <NumberField source="order" />
      <Labeled label="Outlet" source="outlet">
        <OutletField source="outlet" label="Outlet" />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);

export default OutletTableShow;
