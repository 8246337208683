import React, { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import {
  FieldProps,
} from 'react-admin';

const TextArrayField: FC<FieldProps> = ({ record, source }) => {
  const array = record?.[source ?? ''];
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div />;
  }
  return (
    <>
      {(array as string[]).map((item) => <Chip label={item} key={item} />)}
    </>
  );
};

export default TextArrayField;
