import React, { FC } from 'react';
import {
  List, Datagrid, TextField, ListProps, DateField,
} from 'react-admin';
import OutletField from './components/OutletField';
import TextArrayField from './components/TextArrayField';
import OutletPagination from './OutletPagination';

const ReviewList: FC<ListProps> = (props) => {
  const admin = props?.permissions?.admin || false;
  const outletId = props?.permissions?.outletId || '-1';
  const filter = !admin ? {
    outlet: `${outletId}`,
  } : {};
  return (
    <List
      {...props}
      hasShow
      filter={filter}
      perPage={50}
      bulkActionButtons={false}
      sort={{ field: 'created_on', order: 'DESC' }}
      pagination={<OutletPagination />}
    >
      <Datagrid rowClick="show">
        <DateField
          source="created_on"
          label="Date"
          locales="id-ID"
          options={{ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }}
        />
        <TextField
          label="Customer Name"
          source="customer.name"
        />
        <OutletField label="Outlet" />
        <TextField source="rate" />
        <TextArrayField label="What the customer likes (If Rating is 3+) / What can be improved" source="selected_attributes" />
        <TextField source="notes" />
      </Datagrid>
    </List>
  );
};

export default ReviewList;
