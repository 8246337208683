import React, { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  NumberField,
  FilterProps,
  Filter,
  SelectInput,
  useQueryWithStore,
} from 'react-admin';
import OutletField from './components/OutletField';
import OutletPagination from './OutletPagination';

const OutletTableFilter: FC<Partial<FilterProps>> = (props) => {
  const { data } = useQueryWithStore({
    type: 'getList',
    resource: 'hw_outlet_clone',
    payload: {
      sort: { field: 'name', order: 'ASC' },
      filter: {},
      pagination: { page: 1, perPage: 1000 }
    },
  });

  return (
    <Filter {...props}>
        <SelectInput label="Outlet" source="outlet" alwaysOn choices={data ?? []} />
    </Filter>
  );
};

const OutletTableList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      hasShow
      filters={<OutletTableFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={100}
      pagination={<OutletPagination />}
    >
      <Datagrid rowClick="show">
        <TextField source="name" label="Nama Meja" />
        <NumberField source="order" label="Urutan" />
        <OutletField label="Outlet" />
      </Datagrid>
    </List>
  );
};

export default OutletTableList;
