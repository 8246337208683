import React, { FC } from 'react';
import {
  TextInput,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput,
  Edit,
  EditProps,
} from 'react-admin';

const OutletTableEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="order" />
      <ReferenceInput label="Outlet" sort={{ field: 'name', order: 'ASC' }} source="outlet" reference="hw_outlet_clone">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default OutletTableEdit;
