import React, { FC } from 'react';
import {
  Show, SimpleShowLayout, TextField, DateField, ShowProps, Labeled,
} from 'react-admin';
import OutletField from './components/OutletField';
import TableField from './components/TableField';
import TextArrayField from './components/TextArrayField';

const ReviewShow: FC<ShowProps> = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <Labeled label="Table Number" source="table_number">
        <TableField />
      </Labeled>
      <Labeled label="Outlet" source="outlet">
        <OutletField source="outlet" label="Outlet" />
      </Labeled>
      <TextField source="rate" label="Rating" />
      <Labeled label="What the customer likes (If Rating is 3+) / What can be improved" source="selected_attributes">
        <TextArrayField source="selected_attributes" />
      </Labeled>
      <TextField source="notes" />
      <TextField source="customer.name" label="Customer Name" />
      <TextField source="customer.phonenumber" label="Customer Phone Number" />
      <DateField
        label="Reviewed On"
        source="created_on"
        options={{
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }}
      />
    </SimpleShowLayout>
  </Show>
);

export default ReviewShow;
