import React from 'react';
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase';
import { Admin, Resource } from 'react-admin';
import ReviewList from './ReviewList';
import ReviewShow from './ReviewShow';
import OutletList from './OutletList';
import OutletTableList from './OutletTableList';
import OutletTableShow from './OutletTableShow';
import OutletTableCreate from './OutletTableCreate';
import OutletTableEdit from './OutletTableEdit';

const config = {
  apiKey: 'AIzaSyCo-IQgXcUa96l1PM_Jk-IOCvjawq-2e74',
  authDomain: 'holywings-f3b18.firebaseapp.com',
  databaseURL: 'https://holywings-f3b18.firebaseio.com',
  projectId: 'holywings-f3b18',
  storageBucket: 'holywings-f3b18.appspot.com',
  messagingSenderId: '757502401311',
  appId: '1:757502401311:web:e30fa30b43316c37952e2a',
  measurementId: 'G-9QTSR7XQ5Y',
};

// All options are optional
const options = {
  logging: process.env.NODE_ENV !== 'production',
  persistence: 'local',
  // Disable the metadata; 'createdate', 'lastupdate', 'createdby', 'updatedby'
  disableMeta: false,
  // renameMetaFields: {
  //   created_at: 'create', // default: 'createdate'
  //   created_by: 'my_created_by', // default: 'createdby'
  //   updated_at: 'my_updated_at', // default: 'lastupdate'
  //   updated_by: 'my_updated_by', // default: 'updatedby'
  // },
  // Prevents document from getting the ID field added as a property
  dontAddIdFieldToDoc: false,
  softDelete: false,
  associateUsersById: false,
  metaFieldCasing: 'lower',
  // Use firebase sdk queries for pagination, filtering and sorting
  lazyLoading: {
    enabled: true,
  },
  // Logging of all reads performed by app (additional feature, for lazy-loading testing)
  firestoreCostsLogger: {
    enabled: false,
  },
};

export const dataProvider = FirebaseDataProvider(config, options as any);
export const authProvider = FirebaseAuthProvider(config, options as any);

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider}>
    {permissions => [
      <Resource
        name="hw_outlet_reviews"
        options={{ label: 'Reviews' }}
        list={ReviewList}
        show={ReviewShow}
      />,
      permissions?.admin
      ? <Resource
        name="hw_outlet_clone"
        options={{ label: 'Outlets' }}
        list={OutletList}
      />
      : null,
      permissions?.admin
        ? <Resource
          name="hw_outlet_table"
          options={{ label: 'Outlet Tables' }}
          list={OutletTableList}
          show={OutletTableShow}
          edit={OutletTableEdit}
          create={OutletTableCreate}
        />
        : null
    ]}
  </Admin>
);

export default App;
