import React, { FC } from 'react';
import {
  TextFieldProps, useQueryWithStore,
} from 'react-admin';

const OutletField: FC<TextFieldProps> = (props) => {
  const { record } = props;
  const { data, loading } = useQueryWithStore({
    type: 'getOne',
    resource: 'hw_outlet_clone',
    payload: { id: record?.outlet },
  });
  if (loading) {
    return null;
  }
  return <span>{data?.name}</span>;
};

export default OutletField;
